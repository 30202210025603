import React from "react";
import Layout from "../../template/Layout";
import { graphql } from "gatsby";
import SEO from "../../template/seo";
import BreadCrumb from "../../template/BreadCrumb";
import style from "./Privacy.module.scss";

export const query = graphql`
  {
    markdownRemark(frontmatter: {slug: {eq: "/privacystatement/"}}) {
      html
      tableOfContents(pathToSlugField: "frontmatter.slug")
      frontmatter {
        title
        description
      }
    }
  }
`;

type PrivacyPageProps = {
    data: any;
}

const PrivacyPage: React.FC<PrivacyPageProps> = ({ data }) => {
    
    const { frontmatter, html } = data.markdownRemark;
    const { title, description } = frontmatter;
    
    return (<>
        <SEO title={title} description={description} />
            <Layout withBar={true} contentStyle={style.privacyLayout}>
                <BreadCrumb links={[{ url: "/privacystatement/", displayText: "Privacy Statement" }]} />
                <article className={style.privacyContent}>
                  
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                </article>
            </Layout>
        </>
    );
}

export default PrivacyPage;